import htmlParse from "html-react-parser"
import _get from "lodash/get"
import { getConfig } from "@/constants/config"
import { checkStock } from "../src/utils/helper"
import { store } from "@/store"
import { baseUrl, COMPONENT_TYPES } from "@/constants/index"
import {
  setGlobalScripts,
  setGlobalStyles,
} from "@/store/features/genericSlice"
import { getProductAttr, getLineItemAttrs } from "./product"
import Router from "next/router"
import { SANITIZE_TEXT_REGEX } from "@/constants/regex"
import { isBundleWithInstallation } from "@/components/cart/utils"
import { INSTALL_SERVICES } from "./constants"

const isServer = typeof window === "undefined"

export const extractMetasFromDOM = (doc, cb) => {
  const metas = doc.getElementsByTagName("meta")
  for (const meta of metas) {
    cb(meta)
  }
}
export const convertToIndianPhoneNumber = text => {
  try {
    if (text) {
      let value = text
      // let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let numericOnly = value.replace(/\D/g, "")
      numericOnly = numericOnly.substring(0, 10)
      if (/^[0-9]/.test(numericOnly)) {
        return numericOnly
      }
      // return !x[2]
      //   ? "(" + x[1]
      //   : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      // return x;
    } else {
      return
    }
  } catch (error) {
    log.error(error)
  }
}
export const isValidPincode = pincode => {
  if (!pincode) {
    return false
  }
  return /^[0-9]{6,6}$/.test(pincode)
}

export const isValidName = name => {
  return /^[a-zA-Z0-9_ ]*[a-zA-Z0-9_]$/.test(name)
}

export const extractLinksFromDOM = (doc, cb) => {
  const links = doc.getElementsByTagName("link")
  for (const link of links) {
    cb(link)
  }
}

const getAttributeValue = (attributes, name) =>
  _get(
    attributes.find(attr => attr.name === name),
    "value",
    ""
  )

export const getLocationByCookies = async () => {
  const userLocation =
    document.cookie.match("(^|;)\\s*userLocation\\s*=\\s*([^;]+)")?.pop() || ""
  let getCityData = {}
  if (userLocation !== "") {
    try {
      const splitLocation = userLocation.split("+")
      const lat = localStorage.getItem("cookiesLatitude")
      const lng = localStorage.getItem("cookiesLongitude")
      const cityData = localStorage.getItem("cookiesCityData")
      const locationTimeout = localStorage.getItem("cookiesLocationTimeout")
      if (
        parseFloat(lat) === splitLocation[4] &&
        parseFloat(lng) === splitLocation[5] &&
        cityData &&
        locationTimeout &&
        moment().isBefore(moment(locationTimeout))
      ) {
        getCityData = JSON.parse(cityData)
      } else {
        const getReverseGeo = getApiServletPath()
        const reverseGeoLink = getReverseGeo.reverseGeo ?? ""
        getCityData = await getMyCity(
          reverseGeoLink,
          splitLocation[4],
          splitLocation[5]
        )
        const localStorageData = {
          cookiesLatitude: splitLocation[4],
          cookiesLongitude: splitLocation[5],
          cookiesCityData: JSON.stringify(getCityData),
          cookiesLocationTimeout: moment()
            .add("30", "minutes")
            .toISOString(true),
        }
        setLocalStorageData(localStorageData)
      }
    } catch (err) {
      clearLocalStorageData(
        "cookiesLatitude",
        "cookiesLongitude",
        "cookiesCityData",
        "cookiesLocationTimeout"
      )
    }
  }
  return {
    city: _get(getCityData, "city", ""),
    zipcode: _get(getCityData, "zipcode", ""),
  }
}

export const sanitizeInnerHtml = property =>
  htmlParse(`${DOMPurify.sanitize(property)}`)

export const getClienIP = request => {
  let ip = ""
  if (!request) return ip
  if (request.headers["true-ip"]) {
    ip = request.headers["true-ip"].split(",")[0]
  } else if (request.headers["x-forwarded-for"]) {
    ip = request.headers["x-forwarded-for"].split(",")[0]
  } else if (request.headers["x-real-ip"]) {
    ip = request.connection.remoteAddress
  } else {
    ip = request.connection.remoteAddress || ""
  }
  return ip
}

export const getUserAnalyticsObject = (isFromRegisterUser = false) => {
  const hashedEmail =
    localStorage.getItem("hashedEmail") ||
    store?.getState()?.auth?.hashedEmail ||
    ""
  const customerProspect =
    localStorage.getItem("customerProspect") ||
    store?.getState()?.auth?.customerProspect
  let isCustomerOrProspect = "n/a"
  if (hashedEmail && customerProspect) {
    try {
      const decryptedJSON = JSON.parse(
        caesarDecrypt(hashedEmail, customerProspect)
      )
      if (decryptedJSON.pastOrders.length) {
        isCustomerOrProspect = "customer"
      } else {
        isCustomerOrProspect = "prospect"
      }
    } catch (es) {
      // eslint-disable-next-line no-console
      console.log("decrypt error: ", es)
    }
  }
  // this user object should be updated when user log in or out.
  const user = {
    gId: "n/a",
    abTestName: "n/a",
    adBlocker: false,
    authStatus: "anonymous",
    commerceToolsID: "n/a",
    customerProspect: isCustomerOrProspect,
    envName: "development",
    knownStatus: "unknown",
    profileRole: "n/a",
    returningStatus: "new visitor",
    emailId: hashedEmail || "n/a",
  }
  // fetch okta tokens for both logged in and anonymous users
  const oktaToken = localStorage.getItem("okta-token-storage")
  const anonymousOktaToken =
    localStorage.getItem("okta-anonymous-access-token") ||
    store?.getState()?.auth?.access_token
  if (oktaToken && oktaToken !== "{}") {
    // user is logged in
    const oktaObject = JSON.parse(oktaToken)
    const claim = oktaObject?.accessToken?.claims || {}
    // user.uId = claim.uid || "n/a";
    if (!claim.businessType) {
      user.profileRole = "consumer"
    } else {
      user.profileRole = claim.businessType.toLowerCase()
    }
    user.commerceToolsID = claim.commercetoolsID || "n/a"
    if (!isFromRegisterUser) {
      user.returningStatus = "returning customer"
    }
    user.authStatus = "logged in"
    user.knownStatus = "known"
  } else if (anonymousOktaToken) {
    // anonymous user
    let oktaObject
    try {
      oktaObject = JSON.parse(atob(anonymousOktaToken.split(".")[1])) || {}
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Anonymous Okta Token can not be decoded......")
    }
    if (oktaObject) {
      user.gId = oktaObject.gId || "n/a"
    }
  }
  return user
}

export const clearAuthToken = () => {
  localStorage.removeItem("hashedEmail")
  localStorage.removeItem("customerProspect")
  localStorage.removeItem("okta-token-storage")
  localStorage.removeItem("okta-cache-storage")
  localStorage.removeItem("okta-anonymous-access-token")
  localStorage.removeItem("anonymousId")
}

export const clearAnonymousToken = () => {
  localStorage.removeItem("anonymousId")
  localStorage.removeItem("okta-anonymous-access-token")
  localStorage.removeItem("okta-anonymous-refresh-token")
  localStorage.removeItem("token-revalidate-expiry")
}

export const getAccessToken = () => {
  let token = ""
  if (typeof window !== "undefined") {
    token = localStorage.getItem("okta-anonymous-access-token")
  }
  if (!token) token = store.getState()?.auth?.access_token
  if (token) {
    const tokenSplit = token.split(".")[1] || null
    if (tokenSplit) {
      const { gId, exp } = JSON.parse(Buffer.from(tokenSplit, "base64"))
      return { token, gId, exp }
    } else {
      return {}
    }
  }
  return { token }
}

export const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export const getCurrency = async () => {
  const config = await getConfig()
  const { internationalization: { currencyCode = "INR" } = {} } = config
  return currencyCode
}

export const getLWData = async body => {
  let cart = body
  const updatedLineItems = await getLineItemAttrs(cart?.lineItems)
  return { ...cart, lineItems: updatedLineItems }
}

export const calculateCartData = async (params, isCart) => {
  const {
    i18n = {},
    noShippingItems: noShippingItemsProps = [],
    shippingItemstoCheckout = [],
    quantityUpdatedFlag,
  } = params

  let cartObject = params.cart

  const shippingItemsProps = shippingItemstoCheckout

  const CONFIG = await getConfig()
  const locale = _get(CONFIG, "internationalization.locale", "")
  const brandName = _get(CONFIG, "general.siteName", "")?.toLowerCase()
  const currency = _get(CONFIG, "internationalization.currencyCode", "")
  let voucherDiscount = 0
  let MRPsubtotal = 0
  let subtotal = 0
  let subtotalWithDiscount = 0
  let totalPromotions = 0
  let productPromotions = 0
  let couponPromotions = 0
  let estimatedShipping = 0
  let estimatedTax = 0
  let estimatedTotal = 0
  let promoCodes = []
  let oldPromoCodes = []
  let oldDiscountCodes = []
  const cartItems = []
  const specFiles = []
  const outOfStockItems = []
  const noShippingItems = []

  let shippingDiscountsList = 0
  const shippingDiscountsNameList = []
  const voucherCode = []
  const { shippingTotal: { centAmount: shippingTotal } = {} } = cartObject
  oldPromoCodes = cartObject?.promoCode || []
  oldDiscountCodes = cartObject?.discountCodes || []
  if (cartObject?.lineItems && cartObject?.lineItems.length > 0) {
    // Get discount code details
    if (cartObject.promoCode?.length > 0) {
      const discountCodes = cartObject.discountCodes ?? []
      cartObject.promoCode.forEach(item => {
        let discountName
        if (item.code) {
          if (cartObject.discountCodes?.length > 0) {
            const matchedDiscountCode = cartObject.discountCodes.find(
              dc => dc.discountCode?.obj?.code === item.code
            )
            if (matchedDiscountCode?.discountCode.obj.name[locale]) {
              discountName = matchedDiscountCode?.discountCode.obj.name[locale]
            }
          }
          promoCodes.push({
            code: item.code,
            discount: (
              Number(_get(item, "discountedAmount.centAmount", 0)) / 100
            ).toFixed(2),
            id:
              discountCodes.find(
                code => code.discountCode.obj.code === item.code
              )?.discountCode?.id ?? "",
            type: item.type,
            description: item.description ?? "",
          })
        }
        // get product discounts
        else if (item.type === "product") {
          if (promoCodes.find(p => p.type === "product")) {
            const code = promoCodes.find(p => p.type === "product")
            code.discount = (
              Number(code.discount) +
              Number(_get(item, "discountedAmount.centAmount", 0)) / 100
            ).toFixed(2)
          } else {
            promoCodes.unshift({
              code: i18n?.productDiscounts,
              code: "Product Discounts",
              discount: (
                Number(_get(item, "discountedAmount.centAmount", 0)) / 100
              ).toFixed(2),
              id: "",
              type: "product",
              name: discountName,
              description: item.description ?? "",
            })
          }
        }
        // get discounts without promo codes
        else {
          if (promoCodes.find(p => p.code === (item.name || discountName))) {
            const code = promoCodes.find(
              p => p.code === (item.name || discountName)
            )
            code.discount = (
              Number(code.discount) +
              Number(_get(item, "discountedAmount.centAmount", 0)) / 100
            ).toFixed(2)
          } else {
            item.discountedAmount?.centAmount > 0 &&
              promoCodes.unshift({
                code: item.name || discountName,
                discount: (
                  Number(_get(item, "discountedAmount.centAmount", 0)) / 100
                ).toFixed(2),
                id: "",
                type: "no-code",
                description: item.description ?? "",
              })
          }
        }
      })
    }

    // Get shipping promotions
    // if (cartObject.customLineItems?.length > 0) {
    //   promoCodes = promoCodes.concat(
    //     await getShippingDiscountPromoCodes(cartObject)
    //   )
    // }
    //cartObject = cartObject?.lineItems ? await getLWData(cartObject) : []
    let lineItems = cartObject.lineItems ?? []

    if (cartObject.customLineItems?.length > 0) {
      const cusItemWithSku = cartObject.customLineItems.map(item => {
        const modifiedCusItems = { ...item }
        modifiedCusItems.sku = _get(item, "custom.fields.bundleSku", "")
        return modifiedCusItems
      })
      lineItems = await getLineItemAttrs([...cusItemWithSku, ...lineItems])
    } else {
      lineItems = await getLineItemAttrs(lineItems)
    }

    // if (isCart) {
    //   lineItems =
    //     cartObject.lineItems?.filter(
    //       item => !item.custom?.fields?.bundleParentId
    //     ) ?? []
    // }

    lineItems?.forEach((item, i) => {
      //check if bundle parent
      const isBundleParent = _get(item, "custom.fields.isBundleParent", false)
      const isBundleChild = _get(item, "custom.fields.isBundleChildItem", false)
      const MRPPrice = item?.variant?.prices?.find(
        price => !price.customerGroup
      )

      if (!isBundleParent && !isBundleChild) {
        MRPsubtotal = MRPsubtotal + MRPPrice?.value?.centAmount * item.quantity
      }

      if (isBundleParent) {
        MRPsubtotal =
          MRPsubtotal + item?.custom?.fields?.bundleAnyPrice * item.quantity
      }
      let productCategory = _get(item, "lwData.Product_Category")
      if (isBundleChild && productCategory === INSTALL_SERVICES) {
        MRPsubtotal = MRPsubtotal + MRPPrice?.value?.centAmount * item.quantity
      }

      // Get line item price
      let discountPrice = 0
      if (item.price?.discounted) {
        discountPrice = (
          Number(_get(item.price.discounted, "value.centAmount", 0)) / 100
        ).toFixed(2)
      }

      // if (item.discountedPrice) {
      //   discountPrice = (
      //     Number(_get(item.discountedPrice, "value.centAmount", 0)) / 100
      //   ).toFixed(2)
      // }

      if (isBundleParent) {
        discountPrice = (
          Number(_get(item, "custom.fields.bundleDiscountedPrice", 0)) / 100
        ).toFixed(2)
      }

      // MRP Changes might need later
      if (isBundleChild) {
        discountPrice = (
          Number(_get(item.price, "value.centAmount", 0)) / 100
        ).toFixed(2)
      }

      // Get line item details
      const masterVariantKey = item.productKey ?? ""
      const attributes = item.variant?.attributes ?? []
      const productSection = _get(
        attributes.find(attr => attr.name === "ProductSection"),
        "value",
        null
      )
      let productRoom
      if (productSection && productSection[0] && productSection[0][locale]) {
        productRoom = productSection[0][locale]
      }

      const lwAttr = getProductAttr(
        isBundleParent
          ? _get(item, "custom.fields.bundleSku", "")
          : _get(item, "variant.sku", ""),
        item.lwData
      )
      const variantPrices = _get(item, "variant.prices", [])
      let notContains = variantPrices.filter(
        i => !i.hasOwnProperty("customerGroup")
      )[0]?.value?.centAmount
      let anyTotalPrice = 0
      if (productCategory !== INSTALL_SERVICES) {
        anyTotalPrice = (Number(notContains) / 100).toFixed(2)
      }
      cartItems[i] = {
        id: _get(item, "id", ""),
        sku: isBundleParent
          ? _get(item, "custom.fields.bundleSku", "")
          : _get(item, "variant.sku", ""),
        custom: _get(item, "custom", ""),
        customerFacingSKU: getAttributeValue(attributes, "CustomerFacingSKU"),
        productRoom: productRoom,
        slug: isBundleParent
          ? _get(item, "slug", "")
          : _get(item, `productSlug[${locale}]`, ""),
        quantity: _get(item, "quantity", 0),
        image: lwAttr.image,
        category: isBundleParent
          ? _get(item, "custom.fields.productLocalCategory", "")
          : getAttributeValue(attributes, "ProductLocalCategory"),
        brand:
          getAttributeValue(attributes, "CustomerFacingBrand") ||
          lwAttr.brandName,
        masterVariantKey,
        name: _get(
          attributes.find(attr => attr.name === "ProductBrandName"),
          "value",
          _get(item, `name[${locale}]`, "")
        ),
        desc: _get(
          attributes.find(
            attr => attr.name === "ProductDescriptionProductShort"
          ),
          `value`,
          _get(
            attributes.find(attr => attr.name === "ProductDescriptionProduct"),
            "value",
            ""
          )
        ),
        color: lwAttr.finishName,
        bundleMRPPrice: isBundleParent
          ? _get(item, "custom.fields.bundleAnyPrice", 0)
          : 0,
        unitPrice: (
          Number(
            isBundleParent
              ? _get(item, "custom.fields.bundleSalePrice", 0)
              : _get(item, "price.value.centAmount", 0)
          ) / 100
        ).toFixed(2),
        totalPrice: (
          Number(
            isBundleParent
              ? _get(
                  item,
                  "custom.fields.bundleDiscountedPrice",
                  _get(item, "custom.fields.bundleSalePrice", 0)
                ) * _get(item, "state[0].quantity", 1)
              : _get(item, "totalPrice.centAmount", 0)
          ) / 100
        ).toFixed(2),
        discountPrice: discountPrice,
        isComboSku: attributes.find(attr => attr.name === "ComboSKU")
          ? true
          : false,
        backOrder: _get(item, "custom.fields.isBackOrderable", false),
        businessUnitName: _get(
          attributes.find(attr => attr.name === "SPProductBusinessUnit"),
          "value",
          ""
        ),
        additionalData:
          brandName === "annsacks" && item?.custom?.fields?.roomInfo
            ? _get(item, "custom.fields", "")
            : "",
        discountsList: [],
        productCollectionsName:
          getAttributeValue(attributes, "ProductBrandNameDisplay") ||
          getAttributeValue(attributes, "ProductBrandNameDisplay_t"),
        addedAt: _get(item, "addedAt", ""),
        isBundleChildItem: _get(item, "custom.fields.isBundleChildItem", false),
        parentBundleSku: _get(item, "custom.fields.parentBundleSku", ""),
        isBundleParent,
        prices: item?.variant?.prices,
        totalBasePrice: anyTotalPrice,
      }

      if (quantityUpdatedFlag) {
        if (
          quantityUpdatedFlag === cartItems[i].customerFacingSKU?.toLowerCase()
        ) {
          cartItems[i].productUpdated = true
        } else {
          cartItems[i].productUpdated = false
        }
      }

      cartItems[i].superSku = cartItems[i].isComboSku

      //Get bundle discounts
      if (isBundleParent || isBundleChild) {
        if (cartItems[i].discountPrice > 0) {
          const { totalPrice, unitPrice, quantity } = cartItems[i]
          const totalUnitPrice = unitPrice * quantity
          const discountPercent =
            ((totalUnitPrice - totalPrice) / totalUnitPrice) * 100
          cartItems[i].discountPercent = discountPercent.toFixed(2)
        }
      }

      // Get line item discounts
      if (item.price?.discounted) {
        const isAbs =
          item.price?.discounted?.discount?.obj?.value.type === "absolute"
        const isRel = !isAbs
        if (isAbs) {
          cartItems[i].discountAmount = Number(
            _get(
              item.price.discounted,
              "discount.obj.value.money[0].centAmount",
              0
            ) / 100
          )
        } else {
          cartItems[i].discountPercent = Number(
            _get(item.price.discounted, "discount.obj.value.permyriad", 0) / 100
          )
          if (item.price.discounted.discount?.obj?.name[locale]) {
            // sitewide discount
            cartItems[i].discountsList.push({
              type: "sitewide",
              isAbs,
              isRel,
              percent: isRel ? cartItems[i].discountPercent : 0,
              absolute: cartItems[i].discountAmount || 0,
              code: item.price.discounted.discount.obj.name[locale],
            })
          }
        }
      }

      if (item.discountedPrice?.includedDiscounts?.length) {
        item.discountedPrice.includedDiscounts.forEach(includedDiscountItem => {
          const isAbs =
            includedDiscountItem.discount?.obj?.value.type === "absolute"
          const isRel =
            includedDiscountItem.discount?.obj?.value.type === "relative"
          if (isAbs) {
            cartItems[i].discountAmount = Number(
              _get(
                includedDiscountItem,
                "discount.obj.value.money[0].centAmount",
                0
              ) / 100
            )
          } else if (isRel) {
            cartItems[i].discountPercent = Number(
              _get(includedDiscountItem, "discount.obj.value.permyriad", 0) /
                100
            )
          }

          if (isAbs || isRel) {
            const otherDiscount = _get(
              includedDiscountItem,
              `discount.obj.name[${locale}]`,
              null
            )
            const lineItemDiscountName = cartObject.promoCode?.find(
              p => p.type === "order" && p.name === otherDiscount
            )
            if (otherDiscount && lineItemDiscountName) {
              // capture discounts like buy 1 get 1 free. could be like installation service free on design service
              const matchedOrderDiscount = promoCodes.find(
                p => p.type === "no-code" && p.code === otherDiscount
              )
              if (matchedOrderDiscount) {
                // sitewide discount
                cartItems[i].discountsList.push({
                  type: "sitewide",
                  percent: isRel ? cartItems[i].discountPercent : 0,
                  absolute: cartItems[i].discountAmount || 0,
                  isAbs,
                  isRel,
                  code: matchedOrderDiscount.code,
                })
              }
            }
            // find code from discountCode
            if (cartObject.discountCodes?.length) {
              cartObject.discountCodes.forEach(discountCode => {
                const cartDiscounts = _get(
                  discountCode,
                  "discountCode.obj.cartDiscounts",
                  []
                )
                if (cartDiscounts?.length) {
                  cartDiscounts.forEach(discountItem => {
                    if (
                      discountItem.id === includedDiscountItem.discount.obj.id
                    ) {
                      // All coupon discounts
                      cartItems[i].discountsList.push({
                        type: "cart",
                        isAbs,
                        isRel,
                        percent: cartItems[i].discountPercent || 0,
                        absolute: cartItems[i].discountAmount || 0,
                        code: discountCode.discountCode.obj.code,
                      })
                    }
                  })
                }
              })
            }
          }
        })
      }

      const channels = Object.values(
        _get(item, "variant.availability.channels", {})
      )
      cartItems[i].availableQty = channels.reduce(
        (acc, cur) => acc + cur.availableQuantity,
        0
      )

      if (!isBundleParent) {
        // Check if item is out of stock
        if (checkStock(cartItems[i].backOrder, cartItems[i].category)) {
          if (cartItems[i].availableQty < cartItems[i].quantity) {
            cartItems[i].outOfStock = true
            outOfStockItems.push(cartItems[i])
          }
        }
      }

      // get lead time if item is back ordered
      if (cartItems[i].backOrder === true) {
        cartItems[i].leadTime = _get(
          attributes.find(attr => attr.name === "RegionLeadTime"),
          "value",
          0
        )
      }

      // // get lead time if item is back ordered
      // if (cartObject?.newLineItems?.length) {
      //   const lineItemObj = cartObject?.newLineItems.filter(
      //     item => item.sku === cartItems[i].sku
      //   )
      //   cartItems[
      //     i
      //   ].leadTime = `${lineItemObj?.[0]?.eta?.minDay} - ${lineItemObj?.[0]?.eta?.maxDay}`
      // }

      // Check if all items are avilable for shipping
      if (noShippingItemsProps?.includes(item.variant?.sku)) {
        cartItems[i].noShipping = true
        noShippingItems.push(cartItems[i])
      } else if (shippingItemsProps) {
        const shipmentItem = shippingItemsProps?.find(
          shippingItem => shippingItem.lineItemId === item.id
        )
        if (shipmentItem && shipmentItem.warningMessage) {
          if (shipmentItem.parentLineItemId) {
            const parentIndex = cartItems.findIndex(
              c => c.id === shipmentItem.parentLineItemId
            )
            if (parentIndex >= 0 && !cartItems[parentIndex].noShipping) {
              cartItems[parentIndex].noShipping = true
              noShippingItems.push(cartItems[parentIndex])
            }
          } else {
            cartItems[i].noShipping = true
            noShippingItems.push(cartItems[i])
          }
        }
      }

      // Get spec files for all line items
      const productResources = _get(
        attributes.find(attr => attr.name === "ProductResource"),
        "value",
        []
      )
      if (productResources.length > 0) {
        const resource =
          productResources.find(arr => arr[1].value === "SpecPDFFileName") ?? []
        const resourceUrl =
          resource.find(obj => obj.name === "ResourceFullWebURL") ?? {}
        const specFileUrl = resourceUrl.value ?? ""
        if (specFileUrl) specFiles.push(specFileUrl)
      }
    })

    // Get order summary price amounts

    subtotal = Number(
      (cartObject.subtotalWithoutDiscount?.centAmount ?? 0) / 100
    ).toFixed(2)
    subtotalWithDiscount = Number(
      (cartObject.subtotalWithPromotion?.centAmount ?? 0) / 100
    ).toFixed(2)
    totalPromotions = Number(
      (cartObject.totalPromo?.centAmount ?? 0) / 100
    ).toFixed(2)
    const promoDiscounts =
      cartObject?.promoCode?.filter(promo => promo.type === "product") ?? []
    const couponDiscounts =
      cartObject?.promoCode?.filter(promo => promo.type === "order") ?? []
    if (promoDiscounts?.length) {
      productPromotions = promoDiscounts.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.discountedAmount.centAmount,
        0
      )
    }

    if (cartObject.customLineItems) {
      for (let lineItem of cartObject?.customLineItems) {
        const bundleDiscountedPrice =
          lineItem?.custom?.fields?.bundleSalePrice -
          lineItem?.custom?.fields?.bundleDiscountedPrice
        if (!isNaN(bundleDiscountedPrice)) {
          productPromotions = productPromotions + bundleDiscountedPrice
        }
      }
    }
    productPromotions = Number((productPromotions ?? 0) / 100).toFixed(2)
    if (couponDiscounts?.length) {
      couponPromotions = couponDiscounts.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.discountedAmount.centAmount,
        0
      )
    }
    couponPromotions = Number((couponPromotions ?? 0) / 100).toFixed(2)
    estimatedTax = Number(
      cartObject?.custom?.fields?.totalTaxAmountOfCart ?? 0
    ).toFixed(2)

    estimatedShipping = Number((shippingTotal ?? 0) / 100).toFixed(2)
    estimatedTotal = Number(
      (cartObject.estimatedTotal?.centAmount ?? 0) / 100
    ).toFixed(2)

    // checking shipping related discounts and adding it to price object
    promoCodes?.forEach(promo => {
      if (promo.type === "shipping" || promo.type === "no-code") {
        shippingDiscountsList += Number(promo.discount)
        shippingDiscountsNameList.push(promo.code)
      }
    })
    // Add shipping promotions to total promotions
    promoCodes
      .filter(item => item.type === "shipping")
      .forEach(item => {
        estimatedShipping = Number(estimatedShipping) + Number(item.discount)
        // totalPromotions = Number(totalPromotions) + Number(item.discount)
      })
  }

  promoCodes?.forEach(itm => {
    voucherCode.push(itm.code)
    voucherDiscount += Number(itm.discount)
  })

  const analyticsCartItems = await getCartItemsAnalyticsData(cartItems)
  const cart = {
    cartID: cartObject?.id,
    price: {
      basePrice: parseFloat(subtotal) || 0,
      voucherCode: voucherCode.length ? voucherCode.join("|") : "n/a",
      voucherDiscount: voucherDiscount ? Number(voucherDiscount.toFixed(2)) : 0,
      globalOffer: shippingDiscountsNameList
        ? shippingDiscountsNameList.join("|") || "n/a"
        : "n/a",
      globalDiscount: shippingDiscountsList
        ? Number(shippingDiscountsList.toFixed(2))
        : 0,
      currency: currency.toLowerCase(),
      taxRate: parseFloat(estimatedTax) || 0,
      shipping: estimatedShipping ? Number(estimatedShipping) : "n/a",
      priceWithTax: parseFloat(estimatedTotal) || 0,
      cartTotal: parseFloat(subtotal) || 0,
      couponCode: "n/a",
      couponDiscount: 0,
      orderCouponCode: "n/a",
      orderCouponDiscount: 0,
      shippingMethod: "ground",
    },
    ...analyticsCartItems,
  }

  // cartItems.forEach(e => {
  //   const matchedItem = analyticsCartItems?.item?.find(
  //     i => i.productInfo.productID === e.customerFacingSKU
  //   )
  //   if (matchedItem) {
  //     e.customProductInfo = sanitizeDOMString(JSON.stringify(matchedItem))
  //   }
  // })
  cartItems.sort((prev, curr) => {
    return new Date(curr.addedAt) - new Date(prev.addedAt)
  })

  // sanitizeObject(cartItems)
  // sanitizeObject(cart)

  const getFreightDiscountTotal = (codes = []) => {
    let total = 0
    codes.filter(e => {
      if (e.name === "Freight Shipping") {
        total += Number(e.discount)
      }
    })
    return total
  }

  const freightDiscountTotal = getFreightDiscountTotal(promoCodes)

  //map child items to partent
  cartItems.forEach(product => {
    if (product.isBundleParent) {
      product.bundleItems = cartItems.filter(
        item => item.custom?.fields?.parentBundleSku === product.sku
      )
    }
  })
  MRPsubtotal = Number((MRPsubtotal ?? 0) / 100).toFixed(2)
  totalPromotions =
    Number(totalPromotions) + (Number(MRPsubtotal) - Number(subtotal))
  return {
    oldPromoCodes,
    oldDiscountCodes,
    cartItems,
    MRPsubtotal,
    subtotal,
    subtotalWithDiscount,
    totalPromotions,
    couponPromotions,
    productPromotions,
    estimatedTax,
    estimatedShipping,
    estimatedTotal,
    shippingTotal: shippingTotal / 100,
    promoCodes,
    specFiles,
    outOfStockItems,
    noShippingItems,
    shippingDiscountsNameList,
    shippingDiscountsList,
    cart,
    freightDiscountTotal,
  }
}

const getCartItemType = (category = "", businessUnitName = "") => {
  if (!category && !businessUnitName) {
    return "n/a"
  }
  category = category.toLowerCase()
  businessUnitName = businessUnitName.toLowerCase()
  if (
    category === "install services" ||
    category === "design services" ||
    category === "services"
  ) {
    return "service"
  }
  if (category === "samples") {
    return "sample"
  }
  if (
    category === "parts" ||
    category === "service parts" ||
    businessUnitName.indexOf("serviceparts") !== -1 ||
    businessUnitName === "service parts"
  ) {
    return "part"
  }
  return "product"
}

export const sanitizeTextForAnalytics = (text = "") => {
  return text
    ? String(text).replace(SANITIZE_TEXT_REGEX, "").toLowerCase().trim()
    : "n/a"
}

const getCartItemsAnalyticsData = async cartItems => {
  // first parameter should be cartItems
  const { general } = await getConfig()
  const data = { item: [] }

  cartItems.forEach(item => {
    const siteWideCoupanCode = []
    const productCouponCode = []
    const discountPercents = []
    let productCoupondiscount = 0
    let siteWideDiscount = 0
    let newDiscountPercentage = 0

    if (item.discountsList?.length) {
      item.discountsList.forEach(discountItem => {
        if (discountItem.type === "sitewide") {
          // always apply sitewide discount first then remaining can be applied
          siteWideDiscount +=
            Number(item.unitPrice) * (discountItem.percent / 100)
          siteWideCoupanCode.push(discountItem.code?.toLowerCase())
        } else {
          // there can be multiple product coupon codes
          discountPercents.push(discountItem.percent)
          productCouponCode.push(discountItem.code)
        }
      })
      if (discountPercents.length) {
        let priceAfterDiscount = Number(item.unitPrice) - siteWideDiscount
        let temporaryCouponPrice
        for (const disc of discountPercents) {
          temporaryCouponPrice = Number(
            (priceAfterDiscount * (disc / 100)).toFixed(2)
          )
          priceAfterDiscount = priceAfterDiscount - temporaryCouponPrice
          productCoupondiscount += temporaryCouponPrice
        }
        productCoupondiscount = Number(productCoupondiscount.toFixed(2))
      }
    } else if (Number(item.totalBasePrice) > Number(item.unitPrice)) {
      newDiscountPercentage =
        ((Number(item.totalBasePrice) - Number(item.unitPrice)) /
          Number(item.totalBasePrice)) *
        100
      newDiscountPercentage = Number(newDiscountPercentage.toFixed(2))
    }
    const otherItem = {}
    if (item.productUpdated === true || item.productUpdated === false) {
      otherItem.productUpdated = item.productUpdated
    }
    const newDiscount = Number(item?.totalBasePrice - item.unitPrice)
    const globalDiscount = siteWideDiscount
      ? siteWideDiscount
      : Number(item?.totalBasePrice) > 0
      ? newDiscount
      : 0
    const globalPromotionPrice =
      Number(item?.totalBasePrice) > 0
        ? Number(item.unitPrice)
        : Number(item?.totalBasePrice)
    const itemType = getCartItemType(item.category, item.businessUnitName)

    data.item.push({
      productInfo: {
        productBrand: general?.siteName?.toLowerCase(),
        productID: item.customerFacingSKU || item.sku,
        description: sanitizeTextForAnalytics(item.desc),
        productCategory: sanitizeTextForAnalytics(item.category),
        productName: sanitizeTextForAnalytics(
          item.name || item.desc || item.category
        ),
        productColor: sanitizeTextForAnalytics(item.color),
        productRoom: sanitizeTextForAnalytics(
          item.productRoom ||
            (itemType === "service" ? itemType : "non-catalog")
        ),
        productSaleable: true,
        productStatus:
          !checkStock(item.backOrder, item.category) ||
          item.availableQty >= item.quantity ||
          itemType === "service"
            ? "in stock"
            : "out of stock",
        productPartnerBuyNow: false,
        productBasePrice:
          Number(item?.totalBasePrice) > 0
            ? Number(item?.totalBasePrice)
            : Number(item.unitPrice),
        productCouponCode: productCouponCode.join("|") || "n/a",
        productCoupondiscount: productCoupondiscount || 0,
        productSuperSku: item.superSku ? true : false,
        productSalePrice: item.discountPrice
          ? Number(item.discountPrice)
          : Number(item.unitPrice),
        globalPromotionPrice: Number(globalPromotionPrice.toFixed(2)),
        defaultImageName: item.image || "n/a",
        priceState:
          item.discountPercent > 0
            ? "percentOff|" + item.discountPercent
            : item.discountAmount > 0
            ? "priceOff|" + item.discountAmount
            : newDiscountPercentage > 0
            ? "percentOff|" + newDiscountPercentage
            : "regular price",
        productCollectionsName: sanitizeTextForAnalytics(
          item.productCollectionsName || item.category
        ),
        productFindingMethod: "n/a",
        productTileName: sanitizeTextForAnalytics(item.name || item.desc),
        quantity: item.quantity,
        globalOffer: siteWideCoupanCode.join("|") || "n/a",
        globalDiscount: Number(globalDiscount.toFixed(2)),
        itemType,
        isSubscription: false,
        isRecommended: false,
        frequency: "n/a",
        isSendNow: "n/a",
        ratings: "n/a",
        numberOfReviews: "n/a",
        pdpType: "regular finished goods & service parts",
        ...otherItem,
      },
      quantity: item.quantity,
    })
  })
  return data
}
export const updateObject = (objRef, objKey) => {
  objRef[objKey] = sanitizeDOMString(objRef[objKey])
  return objRef
}

export const formatFacetName = (name, filter, facetLabels) => {
  let val =
    facetLabels && facetLabels[name.replace(/^\*{2}/, "")]
      ? facetLabels[name.replace(/^\*{2}/, "")]
      : name
          .replace(/\./g, "/")
          .replace(/_/g, " ")
          .replace(/^\*{2}/, "")
  if (filter) val += ` - ${filter}`
  return val
}

export const getFacetsFromUrl = (marketParamValues = []) => {
  const currFilters = []
  const urlParams = window.location.search
  const aMarkettingParameters = marketParamValues?.marketParamValues

  if (urlParams) {
    urlParams
      .replace("?", "")
      .split("&")
      .forEach(filter => {
        if (
          filter.split("=")[0] !== "title" &&
          filter.split("=")[0] !== "id" &&
          filter.split("=")[0] !== "keyword" &&
          filter.split("=")[0] !== "compare" &&
          filter.split("=")[0] !== "back" &&
          filter.split("=")[0] !== "sort" &&
          filter.split("=")[0] !== "activeTab" &&
          filter.split("=")[0] !== "tab" &&
          filter.split("=")[0] !== "skuSearch" &&
          filter.split("=")[0] !== "service" &&
          !aMarkettingParameters?.includes(filter.split("=")[0])
        ) {
          const facet = decodeURIComponent(filter.split("=")[0])
          if (facet === "wcmmode") return
          const decode = decodeURIComponent(filter)
          let value = decode && decode.split("=")[1].split(",")
          value = value.map(val => val.replace(/[+]/g, " "))
          let display = decode && decode.split("=")[1].split(",")
          display = display.map(val => val.replace(/[+]/g, " "))
          const filterName = decode.split("=")[0]

          display = display.map(d => d.replace(/\\/g, ""))

          // format price range filter
          if (filterName === "Price_Range") {
            display = display.map(range => formatPriceRange(range))
          }

          // Check if the filter attribute already exists
          let checkFacet = false
          currFilters.map(item => {
            checkFacet = Object.values(item).includes(facet)
          })

          // Add a new filter or add values to existing filter
          if (!checkFacet) {
            currFilters.push({
              facet: facet,
              value: value,
              display: display,
            })
          } else {
            currFilters.forEach(fc => {
              if (facet === fc.facet) {
                fc.value.push(value)
                fc.display.push(display)
              }
            })
          }
        }
      })
  }

  return currFilters.filter(currFilter => currFilter.facet !== "sort")
}

export const getAllFilterQueries = (
  componentProps,
  fusion = {},
  marketParamValues = []
) => {
  let currFilters = []
  const urlFilters = getFacetsFromUrl(marketParamValues)
  const facetFields = fusion["facet.field"] ?? []
  const facetLabels = fusion?.facet_labels ?? {}
  if (urlFilters.length > 0) {
    urlFilters.forEach(f => {
      let facetQuery =
        f.facet === "Price_Range"
          ? "Price_Range"
          : Object.keys(facetLabels).find(
              key => facetLabels[key] === f.facet.replace(/_/g, " ")
            )
      if (typeof facetFields === "string") {
        if (facetFields?.includes(f => f === facetQuery)) {
          facetQuery = `**${facetQuery}`
        }
      } else {
        if (facetFields?.find(f => f === facetQuery)) {
          facetQuery = `**${facetQuery}`
        }
      }
      if (facetQuery) {
        f.facet = facetQuery
      }
    })
  }
  // Set pre-selected filters
  if (
    urlFilters?.length === 0 &&
    componentProps?.attributeName !== "" &&
    componentProps?.attributevalue !== ""
  ) {
    let facetQuery = componentProps?.attributeName
    if (facetFields?.includes(f => f === facetQuery)) {
      facetQuery = `**${facetQuery}`
    }
    currFilters.push({
      facet: facetQuery,
      value: [componentProps?.attributevalue.replace(/"/g, '\\"')],
      display: [componentProps?.attributevalue],
    })
  }
  currFilters = [...currFilters, ...urlFilters]
  return currFilters
}

export const mapStateToCountryHandler = (countryData = [], stateData = []) => {
  const data = []
  countryData.map(country => {
    data.push({
      code: country.value,
      name: country.text,
      states: [],
    })
  })
  stateData.map(state => {
    const countryCode = state.value?.split(":")[1]
    data
      .find(country => country.code === countryCode)
      ?.states.push({
        code: state.value?.split(":")[0],
        name: state.text,
      })
  })
  return data
}

export const randomWholeNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const getProductDetails = (
  cartArray = [],
  sku = "",
  isBundleParent = false,
  productData = {}
) => {
  let cartItem = null
  if (isBundleParent) {
    cartItem = cartArray.customLineItems.find(
      item => item.custom?.fields?.bundleSku === sku
    )
  } else {
    const cartItems = cartArray.lineItems.filter(
      item =>
        item.variant.sku === sku && !item.custom?.fields?.isBundleChildItem
    )
    if (cartItems && cartItems.length) {
      if (cartItems.length > 1 && productData?.sku) {
        cartItem = cartItems.find(
          item => item?.custom?.fields?.associatedProduct === productData.sku
        )
      } else {
        cartItem = cartItems[0]
      }
    }
  }
  const isInstallService = isBundleParent
    ? isBundleWithInstallation(sku, cartArray.lineItems)
    : cartItem?.custom?.fields.hasOwnProperty("associatedService")

  let installProduct = null

  if (isBundleParent && isInstallService) {
    installProduct = productData.bundleItems?.find(
      item => item.category === "Install Services"
    )
  }

  const installServiceName =
    isInstallService &&
    (installProduct
      ? installProduct.sku
      : cartItem?.custom?.fields?.associatedService)
  const installServiceQty =
    isInstallService &&
    (installProduct
      ? installProduct.quantity
      : cartItem?.custom?.fields?.associatedServiceQty)
  const installServiceData =
    isInstallService &&
    (installProduct ??
      cartArray?.lineItems?.find(
        item =>
          item?.variant?.sku === installServiceName &&
          item?.custom?.fields?.associatedProduct === productData.sku
      ))
  const totalInstallServiceQty =
    isInstallService && installServiceData?.quantity
  const warehouses = isInstallService && cartItem?.custom?.fields?.warehouses
  const isInstallItem = isBundleParent
    ? false
    : cartItem?.variant?.attributes?.findIndex(
        attribute =>
          attribute.name === "ProductLocalCategory" &&
          attribute.value === "Install Services"
      ) !== -1
  const cartQuantity = cartItem?.quantity ?? 1
  const lineItemId = cartItem?.id
  const isBackOrder = cartItem?.custom?.fields?.isBackOrderable ?? false
  const brand = _get(
    cartItem?.variant?.attributes?.find(
      attr => attr.name === "CustomerFacingBrand"
    ),
    "value",
    ""
  )
  return {
    totalInstallServiceQty,
    cartItem,
    isInstallService,
    installServiceName,
    installServiceQty,
    warehouses,
    isInstallItem,
    cartQuantity,
    lineItemId,
    isBackOrder,
    brand,
  }
}
const extractScriptsFromDOM = (doc, cb) => {
  const scripts = doc.getElementsByTagName("script")
  for (const script of scripts) {
    const scriptItem = {}
    const src = script.getAttribute("src") || ""
    const type = script.getAttribute("type") || ""
    const integrity = script.getAttribute("integrity") || ""
    const charset = script.getAttribute("charset") || ""
    const dataDomainScript = script.getAttribute("data-domain-script") || ""
    if (src) scriptItem.src = src
    if (charset) scriptItem.charset = charset
    if (dataDomainScript) scriptItem.dataDomainScript = dataDomainScript
    if (type) scriptItem.type = type
    if (integrity) scriptItem.integrity = integrity
    if (script.innerHTML) scriptItem.innerHTML = script.innerHTML
    if (process.env.NEXT_PUBLIC_CONSERNBANNER !== src) {
      cb(scriptItem)
    }
  }
}
const extractStylesFromDOM = (doc, cb) => {
  const styles = doc.getElementsByTagName("style")
  for (const style of styles) {
    if (style.innerHTML) cb(style.innerHTML)
  }
}
export const getScriptsAndStyles = jsonData => {
  const scriptsList = []
  const stylesList = []
  const dataRecursion = jsonData => {
    jsonData &&
      jsonData?.[":itemsOrder"] &&
      jsonData?.[":itemsOrder"].map((key, index) => {
        const jsonItem = jsonData[":items"][key]
        if (jsonItem[":type"]?.includes(COMPONENT_TYPES.EMBED)) {
          const doc = new DOMParser().parseFromString(
            jsonItem?.script,
            "text/html"
          )
          extractScriptsFromDOM(doc, item => scriptsList.push(item))
          extractStylesFromDOM(doc, item => stylesList.push(item))
        } else {
          return dataRecursion(jsonItem)
        }
      })
  }
  dataRecursion(jsonData)

  store?.dispatch(setGlobalScripts(scriptsList))
  store?.dispatch(setGlobalStyles(stylesList))
  return scriptsList
}

export const removeAndCleanLink = link => {
  return link
    ? link
        ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
        .join("")
        .replace(".html", "")
    : link
}

export const removeAndCleanLinkConcat = link => {
  return link
    ? link
        ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH + baseUrl)
        .join("")
        .replace(".html", "")
    : link
}

export const getAnalyticLinkUrl = link => {
  return link
    ? (link.indexOf("http") === -1 && typeof window !== "undefined"
        ? window.location.origin
        : "") + link
    : "n/a"
}

export const redirect = url => {
  Router.push(url)
}

export const phoneFormat = mobileNo => {
  return mobileNo
    .toString()
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1 $2 $3 $4")
}

export const formatPriceRange = (filter, isAnnsacksPlp = false) => {
  const values = filter.match(/\d+/g)
  const { internationalization: { currencySign = "₹" } = {} } = getConfig()
  return isAnnsacksPlp
    ? `${currencySign}${Number(
        values[0]
      )?.toLocaleString()} - ${currencySign}${Number(
        values[1]
      )?.toLocaleString()}`
    : `${currencySign}${values[0]} - ${currencySign}${values[1]}`
}

export const getPDPUrl = async (category, slug) => {
  const { plpServletPath, general } = await getConfig()
  const pdpUrl = gePDPUrlPageDetails(plpServletPath, general, category, slug)
  return pdpUrl
}
export const getPDPUrlForProductTile = async data => {
  const category = data.Product_Category
    ? data.Product_Category
    : data.ProductLocalCategory_s ?? ""
  const slug = data.slug_s
  const { plpServletPath, general } = await getConfig()
  const pdpUrl = gePDPUrlPageDetails(plpServletPath, general, category, slug)
  return pdpUrl
}

export const getPDPUrlWithConfig = (
  category,
  slug,
  plpServletPath,
  general
) => {
  const pdpUrl = gePDPUrlPageDetails(plpServletPath, general, category, slug)
  return pdpUrl
}
export const gePDPUrlPageDetails = async (
  plpServletPath,
  general,
  category,
  slug
) => {
  const path = plpServletPath?.find(i => i.categories.includes(category))
  const shortUrl = general.shortendPagePath ?? ""
  if (category === "Design Services") {
    return getShortenedUrlwithGeneral(general.designServiceLandingPath, general)
  }
  if (category === "Install Services") {
    return getShortenedUrlwithGeneral(
      general.installServiceLandingPath,
      general
    )
  }
  if (path) {
    return `${path.url?.replace(shortUrl, "")}/${slug}`
  }

  return `${getShortenedUrlwithGeneral(general.plpPagePath, general)}/${category
    ?.replace(/\s&\s|\s/g, "-")
    .toLowerCase()}/${slug}`
}
export const getShortenedUrlwithGeneral = (uUrl, general) => {
  if (general) {
    const { shortendPagePath } = general
    const shortUrl = shortendPagePath ?? ""
    const htmlRegExp = new RegExp(".html", "g")
    const htmlRemovedUrl = `${uUrl?.replace(htmlRegExp, "")}`
    const shortUrlRegEx = new RegExp(shortUrl, "g")

    return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
  }
  return ""
}

export const isPriceZero = price => price === "0.00" || price === 0

// stateUtils.js

export const stateCodeToName = code => {
  const stateMap = {
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CT: "Chhattisgarh",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TG: "Telangana",
    TR: "Tripura",
    UT: "Uttarakhand",
    UP: "Uttar Pradesh",
    WB: "West Bengal",
    AN: "Andaman and Nicobar Islands",
    CH: "Chandigarh",
    DN: "Dadra and Nagar Haveli and Daman and Diu",
    DL: "Delhi",
    JK: "Jammu and Kashmir",
    LA: "Ladakh",
    LD: "Lakshadweep",
    PY: "Puducherry",
  }

  return stateMap[code] || "State Not Found"
}

export const getUserPersona = () => {
  if (!isServer) {
    const { isAuth, user } = store?.getState()?.auth
    if (isAuth) {
      const persona =
        user?.persona?.length > 0 ? user?.persona[0]?.split(":")[1] : "GST"
      return persona === "CTR" ? "GST" : persona
    }
  }
  return "GST"
}

export const appendPresetToUrl = (
  assetAccountName,
  swatchUrl,
  assetId,
  width,
  presetConfigs = {},
  isReel,
  imageUrl = ""
) => {
  const {
    Desktop,
    MobileLandscape = "",
    MobilePortrait = "",
    LandscapeCategory,
  } = presetConfigs

  if (imageUrl && !imageUrl.includes("/PAWEB/")) {
    return imageUrl
  }

  let preset = ""
  if (width <= 768) {
    if (width <= 425) {
      if (MobilePortrait) {
        preset = MobilePortrait
      }
    } else {
      if (MobileLandscape) {
        preset = MobileLandscape
      }
    }
  } else {
    preset = isReel ? Desktop : MobileLandscape
  }
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${preset}`
}

export const getSortItemBasedOnURL = (text, defaultItem) => {
  const urlParams = window.location.search
  let queryVal = ""
  if (urlParams) {
    const urlQuery = urlParams.replace("?", "").split("&")
    urlQuery.forEach((query, i) => {
      const isBack = query.split("=")[0] === "back"
      if (isBack) {
        sessionStorage.plpScrollSku = query.split("=")[1]
      }
      queryVal += isBack ? "" : `${i > 0 ? "&" : ""}${query}`
    })
    window.history.replaceState(
      window.location.pathname,
      document.title,
      queryVal.length > 0 ? `?${queryVal.toString()}` : window.location.pathname
    )
  }

  const query = getSortFromUrl()
  const selectedSortItem = query
    ? getSelectedSort(text, query)
    : defaultItem || null
  return selectedSortItem
}

const getSortFromUrl = () => {
  const url = new URL(location.href)
  const appliedSort = url.searchParams.get("sort") ?? ""
  return appliedSort.includes("%2B")
    ? appliedSort.replaceAll("%2B", "+")
    : appliedSort.replaceAll(" ", "+")
}

export const getSelectedSort = (text, query) => {
  switch (query) {
    case "bestSeller_l+asc":
      return { name: text.bestSellers, query: `bestSeller_l+asc` }
    case "price+desc":
      return { name: text.priceDesc, query: `price+desc` }
    case "price+asc":
      return { name: text.priceAsc, query: `price+asc` }
    case "CollapseSort_s+asc":
    case "NonCollapseSort_s+asc":
    case "productName_s+asc":
    case "ProductBrandName_s+asc":
    case "title_s+asc":
      return { name: text.atoz, query }
    case "CollapseSort_s+desc":
    case "NonCollapseSort_s+desc":
    case "productName_s+desc":
    case "ProductBrandName_s+desc":
    case "title_s+desc":
      return { name: text.ztoa, query }
    case "new":
      return { name: text.newToOld, query }
    case "old":
      return { name: text.oldToNew, query }
    case "atoz":
      return { name: text.AToZ, query }
    case "ztoa":
      return { name: text.ZToA, query }
  }
  return { name: text.featured, query: `` }
}

export const setBannerConfirmationText = (text = "", id = "") => {
  if (!id) return
  const banner = document.getElementById(id)
  const bannerDesc = banner?.getElementsByClassName(
    "cmp-teaser__description"
  )?.[0]
  const bannerDescElem = bannerDesc?.getElementsByTagName("p")?.[0]
  if (bannerDescElem) {
    bannerDescElem.innerText = text
  }
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "..."
  } else {
    return str
  }
}

export const pdpTypeFormat = (data = {}) => {
  const {
    businessUnitName = "",
    ProductIsRetail = "",
    discontinuedProductb = "",
    ProductRequiredAccessoriesGroupsList = [],
    superSku = "",
  } = data
  let pdpType = ""

  if (businessUnitName === "Service Parts") {
    pdpType = "regular part"
  } else if (ProductIsRetail) {
    pdpType = "retail exclusive"
  } else if (discontinuedProductb) {
    pdpType = "discontinued"
  } else if (ProductRequiredAccessoriesGroupsList.length) {
    pdpType = "regular w/required items"
  } else if (superSku) {
    pdpType = "super sku"
  } else {
    pdpType = "regular finished goods"
  }
  return pdpType
}

export const getStateCodeValue = (
  stateVal = "",
  countryVal = "",
  data = []
) => {
  let country = {}
  const countryValueToCompare = countryVal.toLocaleLowerCase()
  const stateValueToCompare = stateVal.toLocaleLowerCase()
  if (
    data.find(
      obj => (obj?.code ?? "").toLocaleLowerCase() === countryValueToCompare
    )
  ) {
    country = data.find(
      obj => (obj?.code ?? "").toLocaleLowerCase() === countryValueToCompare
    )
  } else {
    country =
      data.find(
        obj => (obj?.name ?? "").toLocaleLowerCase() === countryValueToCompare
      ) ?? {}
  }
  if (
    country.states?.find(
      obj => (obj?.code ?? "").toLocaleLowerCase() === stateValueToCompare
    )
  )
    return stateVal
  const state =
    country.states?.find(
      obj => (obj?.name ?? "").toLocaleLowerCase() === stateValueToCompare
    ) ?? {}
  return state.code ?? ""
}
const handleForOrderconfirmationCall = () => {
  if (
    ["checkout", "cart"].includes(window?.pageObj?.category?.primaryCategory) ||
    window?.pageObj?.category?.primaryCategory === "order confirmation"
  ) {
    return false
  }
  return true
}

const isShowAnalyticsEventLoaded = () => {
  // this function is used to check if cmp:show event is already loaded or not to avoid duplicate show events
  if (window.adobeDataLayer) {
    let isLoaded = false
    window.adobeDataLayer.forEach(event => {
      if (event?.event === "cmp:show") {
        isLoaded = true
      }
    })
    return isLoaded
  }
  return false
}

let pageLoadAnalyticsTimer
export const addPageLoadAnalyticsEvent = () => {
  if (
    window.adobeDataLayer &&
    window.isShowAnalyticsEvent === true &&
    window.pageObj &&
    !isShowAnalyticsEventLoaded() &&
    window.datalayerTopLoaded &&
    handleForOrderconfirmationCall()
  ) {
    // we need to execute cmp:show event here as it is a first time page
    const page = window.pageObj
    const { category: { pageType = "" } = {} } = page

    if (pageLoadAnalyticsTimer) {
      clearTimeout(pageLoadAnalyticsTimer)
      pageLoadAnalyticsTimer = undefined
    }

    if (!pageType) {
      pageLoadAnalyticsTimer = setTimeout(
        () => addPageLoadAnalyticsEvent(),
        500
      )
      return
    }

    if (
      pageType === "pdp" ||
      pageType === "search-results" ||
      pageType === "search"
    ) {
      return
    }

    page.user = localStorage ? getUserAnalyticsObject() : {}

    const otherItems = {}
    if (page.component) {
      otherItems.component = page.component
      // delete page.component
    }
    if (page.eventInfo) {
      otherItems.eventInfo = page.eventInfo
      // delete page.eventInfo
    }
    if (page.productInfo) {
      otherItems.productInfo = page.productInfo
      // delete page.productInfo
    }
    window.adobeDataLayer.push({
      event: "cmp:show",

      page: page,
      ...otherItems,
    })

    window.isShowAnalyticsEvent = false
    delete window.isShowAnalyticsEvent
  }
}

export * from "../src/utils/helper"
